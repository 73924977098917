import React from 'react';
import { css, styled } from '@mui/material/styles';
import { ClickAwayListener, Box, Typography } from '@mui/material';
import { IconVideoCamera } from '@maiia/design-system';
import Link from '../../atoms/Link/Link';
import { APP_ROUTES } from '@/src/constants';
import { useTranslation } from '@/src/i18n';

const SlidingCTAWrapper = styled(Box)(
  ({ theme }) => css`
    position: sticky;
    bottom: 260px;
    margin-left: auto;
    display: flex;
    width: max-content;
    background-color: ${theme.palette.common.white};
    border-radius: ${theme.spacing(4, 0, 0, 4)};
    box-shadow: 0px ${theme.spacing(1)} ${theme.spacing(3)} rgba(0, 0, 0, 0.12);
    overflow-x: hidden; // Necessary to avoid global page overflow
    z-index: 10; // Necessary to sit above page content
    // We can't animate "width" but we can animate "max-width"
    transition: max-width 0.75s ease-out;
  `,
);

const IconWrapper = styled('div')(
  ({ theme }) => css`
    width: ${theme.spacing(5)};
    height: ${theme.spacing(5)};
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacing(0.75)};
    margin: ${theme.spacing(1.5)};
    font-size: ${theme.spacing(2.5)};
    color: ${theme.palette.secondary.contrastText};
    border-radius: 50%;
    background-color: ${theme.palette.secondary.main};

    &::after {
      content: '';
      display: block;
      height: ${theme.spacing(0.75)};
      width: ${theme.spacing(0.75)};
      border-radius: 50%;
      background-color: ${theme.palette.status.online};
      animation: blinker 2.5s linear infinite;
    }
  `,
);

const Label = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.secondary.main};
    margin: 0;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding-right: ${theme.spacing(1.5)};
  `,
);

const SlidingCTA = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    const slideOut = () => {
      setIsOpen(false);
    };

    const welcometimer = setTimeout(() => setIsOpen(true), 500);
    const timer = setTimeout(slideOut, 5000);

    window.addEventListener('scroll', slideOut);
    return () => {
      clearTimeout(welcometimer);
      clearTimeout(timer);

      window.removeEventListener('scroll', slideOut);
    };
  }, []);

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <SlidingCTAWrapper
        data-testid="mobile-sticky-cta"
        onClick={() => setIsOpen(prevState => !prevState)}
        // IconWrapper width (40px) + marginX (24px) = 64px
        sx={{ maxWidth: isOpen ? '100%' : '64px' }}
      >
        <IconWrapper>
          <IconVideoCamera />
        </IconWrapper>

        <Link href={APP_ROUTES.TLC.NO_RDV_SEARCH} passHref>
          <Label variant="h5">
            {t('teleconsultation_without_appointment')}
          </Label>
        </Link>
      </SlidingCTAWrapper>
    </ClickAwayListener>
  );
};

export default SlidingCTA;
