import React from 'react';
import {
  IllustrationVaccination,
  IllustrationTlcCard,
  IllustrationMedications,
} from '@maiia/design-system';
import { APP_ROUTES } from '@/src/constants';
import { useTranslation } from '@/src/i18n';

const CARDS_DATA = [
  {
    content: 'homepage-card-tlc-content',
    MediaComponent: function MediaComponent() {
      const { t } = useTranslation();
      return (
        <IllustrationTlcCard alt={t('teleconsultation')} aria-hidden="true" />
      );
    },
    ariaLabel: 'accessibility_card_tcl',
    buttonLabel: 'homepage-card-tlc-button-label',
    link: APP_ROUTES.TLC.NO_RDV_SEARCH,
    active: true,
  },
  {
    content: 'homepage-card-vaccination-content',
    MediaComponent: function MediaComponent() {
      return <IllustrationVaccination alt="vaccination" aria-hidden="true" />;
    },
    ariaLabel: 'accessibility_search_vaccination_site',
    buttonLabel: 'homepage-card-vaccination-button-label',
    link: APP_ROUTES.VACCINES_NEAR_ME,
    active: true,
  },
  {
    content: 'homepage-card-medications-content',
    MediaComponent: function MediaComponent() {
      const { t } = useTranslation();
      return (
        <IllustrationMedications
          alt={t('drug_information')}
          aria-hidden="true"
        />
      );
    },
    ariaLabel: 'accessibility_search_medication_files',
    buttonLabel: 'homepage-card-medications-button-label',
    link: APP_ROUTES.MEDICAMENTS.BASE_URL,
    active: true,
  },
];
export default CARDS_DATA;
