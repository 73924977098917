/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';

import {
  isEmpty,
  serialActions,
  suggestionsActions,
  addressesActions,
} from '@docavenue/core';
import { useCurrentUser } from '@docavenue/components';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import {
  IconVideoCamera,
  IconSearch,
  VectorLogoMaiiaMonochrome,
  IllustrationPratUnRdv,
} from '@maiia/design-system';

import { Box, Button, IconButton, NoSsr } from '@mui/material';

import Link from '../../atoms/Link/Link';

import SearchBar from '../../molecules/SearchBar/SearchBar';
import Text from '../../atoms/Text/Text';

import { useTranslation } from '@/src/i18n';
import HomeTextBlock from '../../molecules/HomeTextBlock/HomeTextBlock';

import { isPharmagest } from '@/src/utils';
import features, { READY } from '../../../src/features';
import BannerInfoVaccin from '../../molecules/BannerInfoVaccin/BannerInfoVaccin';
import BubbleCTA from '../../molecules/BubbleCTA/BubbleCTA';
import SlidingCTA from '../../molecules/SlidingCTA/SlidingCTA';
import { isMutual } from '@/components/utils/teleconsultationRelay';
import useBranding from '@/src/hooks/branding/useBranding';
import { APP_ROUTES } from '@/src/constants';
import HomePageCards from '../../molecules/HomePageCards/HomePageCards';
import { PatientUser } from '@/components/molecules/WithAuth/types';
import useResponsive from '@/src/hooks/useResponsive';

const HomeConnected = dynamic(() => import('../HomeConnected/HomeConnected'));

const Home = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useCurrentUser<PatientUser>();
  const { data: branding, isBrandedSubdomain } = useBranding();
  const inscriptionCompleted =
    (currentUser?.userPatientInformation.userPatientProfiles?.length ?? 0) > 0;

  const { isMobile } = useResponsive();

  const isUserConnected = !isEmpty(currentUser);

  useEffect(() => {
    dispatch(
      serialActions.serial([
        () => suggestionsActions.reset(),
        () => addressesActions.setItems([]),
        () => addressesActions.setItem(null),
      ]),
    );
  }, []);

  return (
    <div className={clsx('home page', isUserConnected && 'connected')}>
      <div className="home__search">
        {isBrandedSubdomain ? (
          isMutual(branding) ? (
            <div className="bandeau--insurance">
              {branding && (
                <img
                  src={`/files/${branding?.mainPicture?.s3Id}`}
                  alt="big brand logo"
                  className="insurance-logo"
                />
              )}
            </div>
          ) : (
            <div className="bandeau--pharmacy">
              <div className="wrapper-brandname">
                {branding && (
                  <img
                    src={`/files/${branding?.mainPicture?.s3Id}`}
                    alt="big brand logo"
                    className="brandname brandname--pharmacy"
                    width="100"
                  />
                )}
              </div>
            </div>
          )
        ) : (
          // Show branding only if user is not connected
          !isUserConnected && (
            <VectorLogoMaiiaMonochrome
              className="logo--big"
              aria-label="maiia"
            />
          )
        )}

        {branding?.id && !isMutual(branding) && (
          <div className="info--pharmacy">
            <h2 className="name--pharmacie">{branding?.name}</h2>
            <h3 className="subtext--pharmacie">
              {t('invites_teleconsult')}
              {isPharmagest(branding?.domain) ? (
                ''
              ) : (
                <>
                  <span>{t('with_entity', { entity: 'Maiia' })}</span>
                </>
              )}
            </h3>
          </div>
        )}

        {/* Not necessary to wrap with NoSsr because no problem detected  */}
        {isMobile && isUserConnected ? (
          <Box pb={3}>
            <IllustrationPratUnRdv aria-hidden="true" />
          </Box>
        ) : (
          <Text variant="title" size="med" component="h1" className="subtitle">
            {t('home:intro_text')}
          </Text>
        )}

        <SearchBar />
        <Link href={APP_ROUTES.SEARCH.SUGGESTION} passHref>
          <Box
            sx={{
              display: { xs: 'flex', sm: 'none' },
              width: '100%',
              overflow: 'hidden',
              borderRadius: 100,
              boxShadow: '0px 10px 36px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Button
              sx={theme => ({
                py: 2,
                width: '100%',
                whiteSpace: 'nowrap',
                justifyContent: 'start',
                borderRadius: 0,
                color: theme.palette.text.medium,
                backgroundColor: theme.palette.common.white,
                fontWeight: theme.typography.subtitle1.fontWeight,

                '&:hover': {
                  backgroundColor: theme.palette.common.white,
                },
                // Replace overflowing text with "..."
                span: {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
              })}
            >
              <span>{t('home_search_placeholder')}</span>
            </Button>
            <IconButton
              sx={theme => ({
                px: 1,
                width: 65,
                borderRadius: 0,
                fontSize: '36px',
                color: theme.palette.common.white,
                backgroundColor: theme.palette.secondary.main,

                '&:hover': {
                  backgroundColor: theme.palette.secondary.main,
                },
              })}
            >
              <IconSearch />
            </IconButton>
          </Box>
        </Link>
        <HomePageCards />
        {features.vaccinationBanner === READY && (
          <BannerInfoVaccin
            variant="homepage"
            // className={branding?.mainPicture?.s3Id && 'killonmobile'}
          />
        )}

        <NoSsr>
          {!isMobile && (
            <BubbleCTA
              href={APP_ROUTES.TLC.NO_RDV_SEARCH}
              type="tlc"
              label={t('teleconsultation_without_appointment')}
              icon={<IconVideoCamera aria-hidden="true" />}
            />
          )}
        </NoSsr>
      </div>
      <div className="home__wrapper">
        {/* Clip path wave for masking */}
        <svg className="clip-svg">
          <clipPath id="wave-shape" clipPathUnits="objectBoundingBox">
            <path d="m0.001,0 a0.322,0.28,0,0,0,0.111,0.09 c0.027,0.015,0.077,0.03,0.128,0.035 c0.251,0.027,0.385,0.034,0.539,0.119 c0.116,0.057,0.221,0.255,0.221,0.255 s0.002,0.497,0,0.5 s-0.999,0,-0.999,0 s-0.001,-1,0,-0.999 l0,0" />
          </clipPath>
        </svg>

        {isUserConnected ? (
          <HomeConnected inscriptionCompleted={inscriptionCompleted} />
        ) : (
          <HomeTextBlock />
        )}

        {isMobile && <SlidingCTA />}
      </div>
      <div className="wave-bottom">
        <svg className="clip-svg">
          <clipPath id="wave-shape-bottom" clipPathUnits="objectBoundingBox">
            <path d="m0,0.076 c0.183,0.795,0.39,0.671,0.653,0.69 c0.213,0.015,0.311,0.208,0.346,0.302 l0,0 l-1,0.009 s0,-1,0,-1" />
          </clipPath>
        </svg>
      </div>
    </div>
  );
};

export default Home;
