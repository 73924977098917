import { BrandingInformations } from '@/src/hooks/branding/types';

export type TeleconsultationRelay = BrandingInformations & {
  subdomainLabel?: string;
};

export const isMutual = (tlcRelay?: TeleconsultationRelay): boolean =>
  tlcRelay?.type === 'AMC';

export const showAssistedTlcControls = (
  tlcRelay?: TeleconsultationRelay,
): boolean => Boolean(tlcRelay?.code) && !isMutual(tlcRelay);
