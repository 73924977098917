import React from 'react';
import { styled, css } from '@mui/material/styles';
import { Button, Stack, Card, Typography } from '@mui/material';
import Link from '../../atoms/Link/Link';
import { useTranslation } from '@/src/i18n';
import CARDS_DATA from './cardsData';

const CardsList = styled(Stack)(
  ({ theme }) => css`
    overflow-x: auto;
    width: 100vw;
    padding: ${theme.spacing(4)};
    -ms-overflow-style: none; /* Edge, Internet Explorer */
    scrollbar-width: none; /* Firefox */
    /* Chrome, Safari, Opera */
    ::-webkit-scrollbar {
      display: none;
    }
    ${theme.breakpoints.up('sm')} {
      width: 80vw;
      max-width: 1050px;
      justify-content: center;
    }
  `,
);
const Item = styled(Card)(
  ({ theme }) => css`
    border-radius: ${theme.spacing(1)};
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.1);
    display: flex;
    flex: 0 0 65%;
    max-width: 211px;
    padding: ${theme.spacing(1.5)};
    flex-direction: column;

    ${theme.breakpoints.up('sm')} {
      flex: 1;
    }
    ${theme.breakpoints.up('lg')} {
      flex-direction: row;
      max-width: 354px;
      border-radius: ${theme.spacing(0.5)};
    }
  `,
);
const ImgWrapper = styled('div')(
  ({ theme }) => css`
    padding-bottom: ${theme.spacing(1)};

    ${theme.breakpoints.up('lg')} {
      flex-basis: 40%;
      padding-bottom: 0;
    }
    svg {
      max-width: 100%;
    }
  `,
);

const BodyWrapper = styled(Stack)(
  ({ theme }) => css`
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    ${theme.breakpoints.up('lg')} {
      flex-basis: 60%;
      padding-left: ${theme.spacing(1)};
    }
    a {
      align-self: flex-start;
    }
  `,
);

const HomePageCards = () => {
  const { t } = useTranslation();
  const activeCards = CARDS_DATA.filter(card => card.active === true);

  return (
    <CardsList spacing={2} direction="row">
      {activeCards.map(card => (
        <Item key={card.buttonLabel}>
          <ImgWrapper>
            <card.MediaComponent />
          </ImgWrapper>
          <BodyWrapper>
            <Typography fontWeight="medium" pb={1}>
              {t(card.content)}
            </Typography>
            <Link href={card.link} passHref>
              <Button
                color="primary"
                variant="contained"
                data-testid={card.buttonLabel}
                aria-label={t(card?.ariaLabel)}
              >
                {t(card.buttonLabel)}
              </Button>
            </Link>
          </BodyWrapper>
        </Item>
      ))}
    </CardsList>
  );
};
export default HomePageCards;
