/* eslint-disable react/jsx-no-target-blank */
import * as React from 'react';
import clsx from 'clsx';
import { Button } from '@mui/material';
import {
  IllustrationDocuments,
  IllustrationDrugs,
  IllustrationPharmacyBag,
  IllustrationPrat,
  IllustrationTlc,
} from '@maiia/design-system';
import Link from '../../atoms/Link/Link';

import Text from '../../atoms/Text/Text';
import { Trans, useTranslation } from '@/src/i18n';
import { APP_ROUTES, MAP_PHARMARCIES_URL } from '@/src/constants';

type Props = {
  className?: string;
};

const HomeTextBlock = ({ className }: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="home-bloc-wrapper rdv">
        <div className={clsx('home-bloc-content', className)}>
          <div className="home-bloc-text">
            <Text component="h2">
              <Trans
                i18nKey="take_appointment_with_healthcare_professionals"
                defaults="Prenez rendez-vous <0></0> avec vos professionnels de santé"
                components={[<br key="br" />]}
              />
            </Text>
            <Text component="p">
              <Trans
                i18nKey="consult_with_your_practitioners_from_everywhere"
                defaults="En cabinet ou en vidéo, consultez votre docteur, médecin
            généraliste, spécialiste <0>(dentiste, dermatologue, gynécologue, cardiologue, pédiatre,
              ophtalmologue...)</0> ou professionnel paramédical <0>(kinésithérapeute, orthophoniste, orthoptiste, osthéopathe,
                diététicien, podologue...).</0>"
                components={[<span className="killonmobile" key="0" />]}
              />
            </Text>
            <Link href="/medecin-generaliste/75001-PARIS" passHref>
              <Button
                sx={{ mt: 3 }}
                color="secondary"
                variant="contained"
                data-cy="btn-home-get-rdv"
              >
                {t('book_appointment')}
              </Button>
            </Link>
          </div>
          <div className="home-bloc-illu">
            <figure className="illu-wrapper">
              <IllustrationPrat alt={t('practitionner')} aria-hidden="true" />
            </figure>
          </div>
        </div>
      </div>
      <div className="home-bloc-wrapper tlc">
        <div className={clsx('home-bloc-content img-left', className)}>
          <div className="home-bloc-illu">
            <figure className="illu-wrapper">
              <IllustrationTlc alt={t('teleconsultation')} aria-hidden="true" />
            </figure>
          </div>
          <div className="home-bloc-text">
            <Text component="h2">{t('teleconsult_at_any_time')}</Text>
            <Text component="p">
              <Trans
                i18nKey="need_a_quick_diagnostic_teleconsult_at_any_time"
                defaults="Besoin d'un diagnostic rapidement ? Sans vous déplacer ?
                <0></0>
                <1>Nos praticiens sont disponibles tout de suite pour une
                consultation vidéo.</1>
              "
                components={[
                  <br key="br" />,
                  <span className="killonmobile" key="0" />,
                ]}
              />
            </Text>
            <p className="tiny">
              <Trans
                i18nKey="telconsultation_are_refunded"
                defaults="La téléconsultation est remboursée dans le cadre du parcours de
              soins coordonnés.<0></0>"
                components={[<br key="br" />]}
              />
              <Link href={APP_ROUTES.TLC.BASE_URL} passHref>
                <a title={t('learn_more_tlc')} className="link-info">
                  {t('learn_more_about_teleconsultation')}
                </a>
              </Link>
            </p>
            <Link href={APP_ROUTES.TLC.NO_RDV_SEARCH} passHref>
              <Button
                sx={{ mt: 3 }}
                color="secondary"
                variant="contained"
                data-cy="btn-home-go-tlc"
              >
                {t('teleconsult_without_appointment')}
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <div className="home-bloc-wrapper drugs">
        <div className={clsx('home-bloc-content', className)}>
          <div className="home-bloc-text">
            <Text component="h2">
              <Trans
                i18nKey="all_the_details_about_your_drugs_in_just_a_few_clicks"
                defaults="Tous les détails sur vos médicaments,<0></0>en quelques clics"
                components={[<br key="br" />]}
              />
            </Text>
            <Text component="p">
              {t('find_all_the_essential_information_about_your_drugs')}
            </Text>
            <Link href={APP_ROUTES.MEDICAMENTS.BASE_URL} passHref>
              <Button
                sx={{ mt: 3 }}
                color="secondary"
                variant="contained"
                data-cy="btn-home-search-drug"
              >
                {t('search_for_a_drug')}
              </Button>
            </Link>
          </div>
          <div className="home-bloc-illu">
            <figure className="illu-wrapper">
              <IllustrationDrugs alt={t('drug')} aria-hidden="true" />
            </figure>
          </div>
        </div>
      </div>
      <div className="home-bloc-wrapper pharma">
        <div className={clsx('home-bloc-content img-left', className)}>
          <div className="home-bloc-illu">
            <figure className="illu-wrapper">
              <IllustrationPharmacyBag alt={t('pharmacy')} aria-hidden="true" />
            </figure>
          </div>
          <div className="home-bloc-text">
            <Text component="h2">{t('teleconsult_from_pharmacies')}</Text>
            <Text component="p">
              {t(
                'teleconsult_from_your_pharmacy_and_share_documents_with_practitioners',
              )}
            </Text>
            <Button
              color="secondary"
              variant="contained"
              href={MAP_PHARMARCIES_URL}
              target="_blank"
              data-cy="btn-home-find-pharmacy"
              sx={{ mt: 3 }}
            >
              {t('find_a_pharmacy')}
            </Button>
          </div>
        </div>
      </div>
      <div className="home-bloc-wrapper documents">
        <div className={clsx('home-bloc-content', className)}>
          <div className="home-bloc-text">
            <Text component="h2">
              <Trans
                i18nKey="your_documents_always_with_you"
                defaults="Vos documents de santé,<0></0>toujours avec vous"
                components={[<br key="br" />]}
              />
            </Text>
            <Text component="p">
              {t('store_your_medical_documents_in_secured_place')}
            </Text>
            <Link href={APP_ROUTES.DOCUMENTS} passHref>
              <Button
                sx={{ mt: 3 }}
                color="secondary"
                variant="contained"
                data-cy="btn-home-go-doc"
              >
                {t('create_your_medical_folder')}
              </Button>
            </Link>
          </div>
          <div className="home-bloc-illu">
            <figure className="illu-wrapper">
              <IllustrationDocuments
                alt={t('my_health_documents')}
                aria-hidden="true"
              />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeTextBlock;
