import React from 'react';
import { Button } from '@mui/material';
import { css, styled } from '@mui/material/styles';
import { devices, ClipPathBubbleCTA } from '@maiia/design-system';
import Link from '../../atoms/Link/Link';
import { analyticsEvent } from '@/src/analytic_tag_manager';

/* JS styling */

const BubbleCTAWrapper = styled('div')(
  ({ theme }) => css`
    @media ${devices.mobile} {
      display: none;
    }
    @media ${devices['tablet+desktop']} {
      position: absolute;
      right: 0;
      &:not(:hover) {
        animation: bounce-bubble-cta 4s 2s ease infinite; // Bounce animation
      }
    }
    @media ${devices.tablet} {
      top: ${theme.spacing(12)};
      width: ${theme.spacing(16)};
    }
    @media ${devices.desktop} {
      top: ${theme.spacing(16)};
      width: ${theme.spacing(24)};
    }

    .page.drugs-home & {
      @media ${devices['tablet+desktop']} {
        z-index: 1; // Necessary to sit above wave section on BCB page
      }
      @media ${devices.tablet} {
        top: ${theme.spacing(4)};
      }
      @media ${devices.desktop} {
        top: ${theme.spacing(6)};
      }
    }
  `,
);

const BubbleButton = styled(Button)(
  ({ theme }) => css`
    @media ${devices.mobile} {
      width: 100%;
      max-width: ${theme.spacing(48)};
      min-height: ${theme.spacing(
        6,
      )}; // Force height to be the same as the search field
      color: ${theme.palette.common.white};
      border-color: ${theme.palette.common.white};
      background-color: ${theme.palette.background.primaryTransparent};
      &:hover {
        border-color: ${theme.palette.common.white};
        background-color: ${theme.palette.background.primaryTransparent};
      }
    }
    @media ${devices['tablet+desktop']} {
      flex-direction: column; // Make sure button label and icon go below each other
      min-width: revert;
      font-weight: 700;
      text-align: center;
      color: ${theme.palette.secondary.main};
      background-color: ${theme.palette.common
        .white}; // Color of the wave shape background
      clip-path: url(#clipPathBubbleCTA); // We need to use this technique to have an hover effect when mouse goes over the wave shape only
      border: none;
      border-radius: 0;
      &:hover {
        color: ${theme.palette.secondary.dark};
        background-color: ${theme.palette.common.white};
        border: none;

        .MuiButton-endIcon {
          background-color: ${theme.palette.secondary.dark};
        }
      }
    }
    @media ${devices.tablet} {
      font-size: ${theme.spacing(1.75)};
      padding: ${theme.spacing(6.5, 1, 8, 2)};
    }
    @media ${devices.desktop} {
      font-size: ${theme.spacing(2.75)};
      padding: ${theme.spacing(9, 2, 11, 4)};
    }

    .MuiButton-endIcon {
      position: relative; // Necessary for ripple effect

      svg {
        font-size: ${theme.spacing(
          3,
        )}; // Increase slightly the size of the icon
      }

      // Ripple effect
      @media ${devices['tablet+desktop']} {
        color: ${theme.palette.common.white};
        border-radius: 50%;
        margin-left: 0;
        background-color: ${theme.palette.secondary.main};
        animation: pulse-accent 2.5s infinite;

        &:before {
          content: '';
          position: absolute;
          display: block;
          height: 120%;
          width: 120%;
          top: 50%;
          left: 50%;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          z-index: -1;
        }

        svg {
          z-index: 20; // Make sure icon is sitting above ripple effect
        }
      }
      @media ${devices.tablet} {
        padding: ${theme.spacing(1)};
        margin-top: ${theme.spacing(1.5)};

        svg {
          font-size: ${theme.spacing(4)};
        }
      }
      @media ${devices.desktop} {
        padding: ${theme.spacing(1.5)};
        margin-top: ${theme.spacing(2)};

        svg {
          font-size: ${theme.spacing(5)};
        }
      }
    }

    .page.home & {
      .MuiButton-endIcon {
        // Online badge
        &:after {
          content: '';
          position: absolute;
          display: block;
          top: 50%;
          right: ${theme.spacing(0.5)};
          height: ${theme.spacing(1.5)};
          width: ${theme.spacing(1.5)};
          border-radius: 50%;
          background-color: ${theme.palette.status.online};
          animation: blinker 2.5s linear infinite;
          transform: translateY(-50%);
          z-index: 20;
        }

        @media ${devices.mobile} {
          padding-right: ${theme.spacing(2.5)}; // Leave space for online badge
        }
        @media ${devices.tablet} {
          padding: ${theme.spacing(1.5)};
          // Online badge
          &:after {
            height: ${theme.spacing(1)};
            width: ${theme.spacing(1)};
            right: ${theme.spacing(0.75)};
          }
          // Camera icon
          svg {
            font-size: ${theme.spacing(3)};
            transform: translateX(${theme.spacing(-0.5)});
          }
        }
        @media ${devices.desktop} {
          padding: ${theme.spacing(2)};
          // Online badge
          &:after {
            right: ${theme.spacing(1.25)};
          }
          // Camera icon
          svg {
            transform: translateX(${theme.spacing(-1)});
          }
        }
      }
    }
    .page.drugs-home & {
      .MuiButton-endIcon {
        @media ${devices.mobile} {
          display: none; // On this specific page and on mobile, hide search icon as the same icon is already used just above (drugs search field)
        }
      }
    }
  `,
);

/* Component */

type Props = {
  type: string;
  href: string;
  label: string;
  icon: React.ReactElement;
};

const BubbleCTA = ({ href, type, label, icon }: Props) => (
  <BubbleCTAWrapper data-cy="bubble-button">
    <Link href={href} passHref>
      <BubbleButton
        endIcon={icon}
        onClick={() => {
          if (type === 'tlc')
            analyticsEvent({
              category: 'Home',
              action: 'ClickSearchDirectTLC',
            });
        }}
        title={label}
        aria-label={label}
      >
        {label}
      </BubbleButton>
    </Link>
    <ClipPathBubbleCTA />
  </BubbleCTAWrapper>
);

export default BubbleCTA;
