import React from 'react';
import clsx from 'clsx';

type Props = {
  className?: string;
  children?: React.ReactNode;
  component?: 'h1' | 'h2' | 'h3' | 'h4' | 'p' | 'span';
  iconComponent?: React.ReactNode;
  variant?: 'paragraph' | 'title';
  direction?: 'icon-right';
  size?:
    | 'xsmall'
    | 'small'
    | 'mention'
    | 'med'
    | 'medlarge'
    | 'large'
    | 'xlarge';
  iconColor?: 'main' | 'current' | 'accent' | 'alert';
} & React.PropsWithoutRef<
  JSX.IntrinsicElements['h1' | 'h2' | 'h3' | 'h4' | 'p' | 'span']
>;

const Text: React.FC<Props> = ({
  className,
  variant,
  children,
  component: Component = 'span',
  iconComponent,
  iconColor,
  direction,
  size,
  ...props
}) => {
  const _className = clsx(
    className,
    variant && `${variant}`,
    size && `${variant}--${size}`,
    iconComponent && `with-icon`,
    iconColor && `${iconColor}`,
    direction === `icon-right` && `${direction}`,
  );

  return (
    <Component className={_className} {...props}>
      {iconComponent && <>{iconComponent}</>}
      {children}
    </Component>
  );
};

export default Text;
